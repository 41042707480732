import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridFilteredMentors from "./SectionGridFilteredMentors";

export interface MentorListingProps {
  className?: string;
}

export interface SectionGridFilterCardProps {
  className?: string;
}

const MentorsListingPage: FC<MentorListingProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-ListingCarPage relative overflow-hidden ${className}`}
      data-nc-id="ListingCarPage"
    >
      <Helmet>
        <title>QA-Offer || The Best Place to start</title>
      </Helmet>
      <BgGlassmorphism />
      <div className="container relative">
        <SectionGridFilteredMentors className="" />
      </div>
    </div>
  );
};

export default MentorsListingPage;
