import React, { useEffect, useState } from "react";
import { FC } from "react";
import CommonLayout from "./CommonLayout";
import {
  CreateMentorsScheduleRequest,
  MentorSchedule,
  MentorScheduleResponseWithOffset,
  MentorScheduleWithInterval,
} from "models/ScheduleModels/CreateMentorsScheduleRequest";
import { WeekDay, Availability } from "models/ScheduleModels/Enums";
import scheduleService from "services/ScheduleService";
import { useNavigate } from "react-router-dom";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import moment from "moment-timezone";
import DateHelper from "helpers/dateStringHelper";
import { set } from "lodash";

export interface TimeSlotProps {
  time: string;
  isAvailable: Availability;
  onClick: () => void;
}

const TimeSlot: FC<TimeSlotProps> = ({ time, isAvailable, onClick }) => {
  return (
    <button
      className={`p-2 ${
        isAvailable === Availability.Available
          ? "bg-green-500 text-white"
          : "bg-gray-300 text-gray-600"
      }`}
      onClick={onClick}
    >
      {time}
    </button>
  );
};

const ScheduleForm = () => {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const times = [
    "04:00 AM",
    "04:30 AM",
    "05:00 AM",
    "05:30 AM",
    "06:00 AM",
    "06:30 AM",
    "07:00 AM",
    "07:30 AM",
    "08:00 AM",
    "08:30 AM",
    "09:00 AM",
    "09:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "01:00 PM",
    "01:30 PM",
    "02:00 PM",
    "02:30 PM",
    "03:00 PM",
    "03:30 PM",
    "04:00 PM",
    "04:30 PM",
    "05:00 PM",
    "05:30 PM",
    "06:00 PM",
    "06:30 PM",
    "07:00 PM",
    "07:30 PM",
    "08:00 PM",
    "08:30 PM",
    "09:00 PM",
    "09:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
    "11:30 PM",
  ];

  const navigate = useNavigate();

  useEffect(() => {
    const role = localStorage.getItem("role")!;
    if (role !== "mentor") {
      navigate("/login");
      return;
    }
  }, []);

  const [requestSuccessMsg, setRequestSuccessMsg] = React.useState<string>("");

  const [requestErrorMessage, setRequestErrorMessage] = React.useState<
    string | undefined
  >("");
  const [mondaySchedule, setMondaySchedule] = useState<
    Map<string, Availability>
  >(new Map(times.map((time) => [time, Availability.Unavailable])));
  const [tuesdaySchedule, setTuesdaySchedule] = useState<
    Map<string, Availability>
  >(new Map(times.map((time) => [time, Availability.Unavailable])));
  const [wednesdaySchedule, setWednesdaySchedule] = useState<
    Map<string, Availability>
  >(new Map(times.map((time) => [time, Availability.Unavailable])));
  const [thursdaySchedule, setThursdaySchedule] = useState<
    Map<string, Availability>
  >(new Map(times.map((time) => [time, Availability.Unavailable])));
  const [fridaySchedule, setFridaySchedule] = useState<
    Map<string, Availability>
  >(new Map(times.map((time) => [time, Availability.Unavailable])));
  const [saturdaySchedule, setSaturdaySchedule] = useState<
    Map<string, Availability>
  >(new Map(times.map((time) => [time, Availability.Unavailable])));
  const [sundaySchedule, setSundaySchedule] = useState<
    Map<string, Availability>
  >(new Map(times.map((time) => [time, Availability.Unavailable])));

  const handleTimeSlotClick = (day: String, time: string) => {
    setRequestSuccessMsg("");
    switch (day) {
      case "Monday":
        setMondaySchedule((prevSchedule) => {
          const updatedSchedule = new Map(prevSchedule);
          const currentAvailability = updatedSchedule.get(time);
          if (currentAvailability === Availability.Available) {
            updatedSchedule.set(time, Availability.Unavailable);
          } else {
            updatedSchedule.set(time, Availability.Available);
          }
          return updatedSchedule;
        });
        break;
      case "Tuesday":
        setTuesdaySchedule((prevSchedule) => {
          const updatedSchedule = new Map(prevSchedule);
          const currentAvailability = updatedSchedule.get(time);
          if (currentAvailability === Availability.Available) {
            updatedSchedule.set(time, Availability.Unavailable);
          } else {
            updatedSchedule.set(time, Availability.Available);
          }
          return updatedSchedule;
        });
        break;
      case "Wednesday":
        setWednesdaySchedule((prevSchedule) => {
          const updatedSchedule = new Map(prevSchedule);
          const currentAvailability = updatedSchedule.get(time);
          if (currentAvailability === Availability.Available) {
            updatedSchedule.set(time, Availability.Unavailable);
          } else {
            updatedSchedule.set(time, Availability.Available);
          }
          return updatedSchedule;
        });
        break;
      case "Thursday":
        setThursdaySchedule((prevSchedule) => {
          const updatedSchedule = new Map(prevSchedule);
          const currentAvailability = updatedSchedule.get(time);
          if (currentAvailability === Availability.Available) {
            updatedSchedule.set(time, Availability.Unavailable);
          } else {
            updatedSchedule.set(time, Availability.Available);
          }
          return updatedSchedule;
        });
        break;
      case "Friday":
        setFridaySchedule((prevSchedule) => {
          const updatedSchedule = new Map(prevSchedule);
          const currentAvailability = updatedSchedule.get(time);
          if (currentAvailability === Availability.Available) {
            updatedSchedule.set(time, Availability.Unavailable);
          } else {
            updatedSchedule.set(time, Availability.Available);
          }
          return updatedSchedule;
        });
        break;
      case "Saturday":
        setSaturdaySchedule((prevSchedule) => {
          const updatedSchedule = new Map(prevSchedule);
          const currentAvailability = updatedSchedule.get(time);
          if (currentAvailability === Availability.Available) {
            updatedSchedule.set(time, Availability.Unavailable);
          } else {
            updatedSchedule.set(time, Availability.Available);
          }
          return updatedSchedule;
        });
        break;
      case "Sunday":
        setSundaySchedule((prevSchedule) => {
          const updatedSchedule = new Map(prevSchedule);
          const currentAvailability = updatedSchedule.get(time);
          if (currentAvailability === Availability.Available) {
            updatedSchedule.set(time, Availability.Unavailable);
          } else {
            updatedSchedule.set(time, Availability.Available);
          }
          return updatedSchedule;
        });
        break;
      default:
        break;
    }
  };

  const convertTo24Hour = (time: string, availability: Availability) => {
    // time is a string like '10:00 PM' convert it to 24 hour format 22:00
    return { time: DateHelper.formatTime(time)!, availability: availability };
  };

  const sendSchedule = async () => {
    setRequestErrorMessage("");
    var scheduleRequest: CreateMentorsScheduleRequest = {
      days: [
        {
          weekDay: WeekDay.Monday,
          slots: Array.from(mondaySchedule, ([key, value]) => {
            return convertTo24Hour(key, value);
          }),
        },
        {
          weekDay: WeekDay.Tuesday,
          slots: Array.from(tuesdaySchedule, ([key, value]) => {
            return convertTo24Hour(key, value);
          }),
        },
        {
          weekDay: WeekDay.Wednesday,
          slots: Array.from(wednesdaySchedule, ([key, value]) => {
            return convertTo24Hour(key, value);
          }),
        },
        {
          weekDay: WeekDay.Thursday,
          slots: Array.from(thursdaySchedule, ([key, value]) => {
            return convertTo24Hour(key, value);
          }),
        },
        {
          weekDay: WeekDay.Friday,
          slots: Array.from(fridaySchedule, ([key, value]) => {
            return convertTo24Hour(key, value);
          }),
        },
        {
          weekDay: WeekDay.Saturday,
          slots: Array.from(saturdaySchedule, ([key, value]) => {
            return convertTo24Hour(key, value);
          }),
        },
        {
          weekDay: WeekDay.Sunday,
          slots: Array.from(sundaySchedule, ([key, value]) => {
            return convertTo24Hour(key, value);
          }),
        },
      ],
      timezoneId: moment.tz.guess(),
    };

    const result = await scheduleService.createMentorsSchedule(scheduleRequest);
    if (result?.statusCode !== 200 && result?.statusCode! < 500) {
      setRequestErrorMessage(result?.errorMessage);
      return;
    }

    if (result?.statusCode! >= 500) {
      setRequestErrorMessage(
        "An error occurred during the request. Please try again later."
      );
      return;
    }

    setRequestSuccessMsg("Schedule updated successfully!");
  };

  const getSchedule = async () => {
    const result =
      await scheduleService.getMentorsWeekSchedule<MentorScheduleResponseWithOffset>();
    if (result?.statusCode !== 200 && result?.statusCode! < 500) {
      setRequestErrorMessage(result?.errorMessage);
      return;
    }

    if (result?.statusCode! >= 500) {
      setRequestErrorMessage(
        "An error occurred during the request. Please try again later."
      );
      return;
    }

    const schedule = result?.data?.data;
    if (schedule && schedule.days.length > 0) {
      const mondaySchedule = new Map(
        schedule.days
          .find((day) => day.weekDay === WeekDay.Monday)
          ?.slots.map((timeSlot) => [
            DateHelper.formatTimeTo12Hour(timeSlot.time)!,
            timeSlot.availability,
          ])
      );
      const tuesdaySchedule = new Map(
        schedule.days
          .find((day) => day.weekDay === WeekDay.Tuesday)
          ?.slots.map((timeSlot) => [
            DateHelper.formatTimeTo12Hour(timeSlot.time)!,
            timeSlot.availability,
          ])
      );
      const wednesdaySchedule = new Map(
        schedule.days
          .find((day) => day.weekDay === WeekDay.Wednesday)
          ?.slots.map((timeSlot) => [
            DateHelper.formatTimeTo12Hour(timeSlot.time)!,
            timeSlot.availability,
          ])
      );
      const thursdaySchedule = new Map(
        schedule.days
          .find((day) => day.weekDay === WeekDay.Thursday)
          ?.slots.map((timeSlot) => [
            DateHelper.formatTimeTo12Hour(timeSlot.time)!,
            timeSlot.availability,
          ])
      );
      const fridaySchedule = new Map(
        schedule.days
          .find((day) => day.weekDay === WeekDay.Friday)
          ?.slots.map((timeSlot) => [
            DateHelper.formatTimeTo12Hour(timeSlot.time)!,
            timeSlot.availability,
          ])
      );
      const saturdaySchedule = new Map(
        schedule.days
          .find((day) => day.weekDay === WeekDay.Saturday)
          ?.slots.map((timeSlot) => [
            DateHelper.formatTimeTo12Hour(timeSlot.time)!,
            timeSlot.availability,
          ])
      );
      const sundaySchedule = new Map(
        schedule.days
          .find((day) => day.weekDay === WeekDay.Sunday)
          ?.slots.map((timeSlot) => [
            DateHelper.formatTimeTo12Hour(timeSlot.time)!,
            timeSlot.availability,
          ])
      );

      setMondaySchedule(mondaySchedule);
      setTuesdaySchedule(tuesdaySchedule);
      setWednesdaySchedule(wednesdaySchedule);
      setThursdaySchedule(thursdaySchedule);
      setFridaySchedule(fridaySchedule);
      setSaturdaySchedule(saturdaySchedule);
      setSundaySchedule(sundaySchedule);
    }
  };

  useEffect(() => {
    //send only once
    getSchedule();
  }, []);

  return (
    <div>
      <CommonLayout role={localStorage.getItem("role")!}>
        <h2 className="text-2xl font-semibold mb-4">
          Your Schedule ({`${moment.tz.guess()} ${moment().format("Z")}`})
        </h2>
        <div className="grid grid-cols-7 gap-4">
          {/* Empty cell for alignment */}
          {/* <div></div> */}
          {days.map((day) => (
            <div key={day} className="text-center font-semibold">
              {day}
            </div>
          ))}
          {times.map((time, timeIndex) => (
            <React.Fragment key={time}>
              <TimeSlot
                time={time}
                isAvailable={sundaySchedule.get(time)!}
                onClick={() => handleTimeSlotClick("Sunday", time)}
              />
              <TimeSlot
                time={time}
                isAvailable={mondaySchedule.get(time)!}
                onClick={() => handleTimeSlotClick("Monday", time)}
              />
              <TimeSlot
                time={time}
                isAvailable={tuesdaySchedule.get(time)!}
                onClick={() => handleTimeSlotClick("Tuesday", time)}
              />
              <TimeSlot
                time={time}
                isAvailable={wednesdaySchedule.get(time)!}
                onClick={() => handleTimeSlotClick("Wednesday", time)}
              />
              <TimeSlot
                time={time}
                isAvailable={thursdaySchedule.get(time)!}
                onClick={() => handleTimeSlotClick("Thursday", time)}
              />
              <TimeSlot
                time={time}
                isAvailable={fridaySchedule.get(time)!}
                onClick={() => handleTimeSlotClick("Friday", time)}
              />
              <TimeSlot
                time={time}
                isAvailable={saturdaySchedule.get(time)!}
                onClick={() => handleTimeSlotClick("Saturday", time)}
              />
            </React.Fragment>
            // <React.Fragment key={time}>
            //   {/* <div className="font-semibold text-right">{time}</div> */}
            //   {schedule.map((daySlots, dayIndex) => (
            //     <TimeSlot
            //       key={`${dayIndex}-${timeIndex}`}
            //       time={time}
            //       isAvailable={daySlots[timeIndex]}
            //       onClick={() => handleTimeSlotClick(dayIndex, timeIndex)}
            //     />
            //   ))}
            // </React.Fragment>
          ))}
        </div>
        <div className="mt-4">
          <button
            className="px-4 py-2 bg-blue-500 text-white font-semibold rounded shadow"
            onClick={sendSchedule}
          >
            Send Schedule
          </button>
          <span className="mx-2"></span>
          <ButtonSecondary href="/mentor-account">Settings</ButtonSecondary>
          {requestErrorMessage !== "" ? (
            <span className="block text-center text-red-500 dark:text-red-500">
              {requestErrorMessage}
            </span>
          ) : null}
          {requestSuccessMsg !== "" ? (
            <span className="block text-center text-green-500 dark:text-green-500">
              {requestSuccessMsg}
            </span>
          ) : null}
        </div>
      </CommonLayout>
    </div>
  );
};

export default ScheduleForm;
