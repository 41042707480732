import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/Photo_v2.png";

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  const scrollToForm = () => {
    const headerHeight = 10;
    var element = document.getElementById("booking-form");
    var formPosition = element?.offsetTop;
    window.scrollTo({
      top: formPosition! - headerHeight,
      behavior: "smooth",
    });
    //element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl !leading-[114%] ">
            QA Offer - The platform to learn and grow
          </h2>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            Full support on your journey to become a technology professional
          </span>
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
            <ButtonPrimary onClick={scrollToForm}>
              Book a free consultation
            </ButtonPrimary>
            {/* <ButtonPrimary href="/signup-mentors">
              Become a mentor
            </ButtonPrimary> */}
          </div>
        </div>
        <div className="flex-grow">
          <img className="w-full" src={imagePng} alt="hero" />
        </div>
      </div>

      {/* <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
        <HeroSearchForm />
      </div> */}
    </div>
  );
};

export default SectionHero;
