import Label from "components/Label/Label";
import { useNavigate } from "react-router-dom";
import React, { FC, useEffect } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import MoneyInput from "shared/Input/MoneyInput";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import accountService from "services/AccountService";
import { UpdateMentorProfileModel } from "models/ProfileModels/UpdateMentorProfileModel";

export interface AccountPageProps {
  className?: string;
}

interface MentorAccountInfoResponse {
  firstName: string | null;
  lastName: string | null;
  bio: string | null;
  birthDate: string | null;
  phoneNumber: string | null;
  price: number | null;
  linkedInProfileUrl: string | null;
}

const MentorAccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = React.useState<UpdateMentorProfileModel>({
    firstName: "",
    lastName: "",
    bio: "",
    birthDate: null,
    phoneNumber: "",
    price: 0,
    linkedInProfileUrl: "",
  });

  const [imageUrl, setImageUrl] = React.useState<string | undefined>("");
  const [fileForm, setFileForm] = React.useState<FormData | null>(null);

  const [successMsg, setSuccessMsg] = React.useState<string>("");

  const [errorResponse, setErrorResponse] = React.useState<
    string | undefined
  >();

  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role === undefined) {
      navigate("/login");
    } else if (role !== "mentor") {
      navigate("/student");
    }

    accountService
      .getAccountInfo<MentorAccountInfoResponse>("mentor")
      .then((res) => {
        if (res?.statusCode && res.statusCode >= 400 && res.statusCode < 500) {
          // Use optional chaining with statusCode
          setErrorResponse(res.errorMessage);
        } else if (res?.statusCode && res.statusCode >= 500) {
          // Use optional chaining with statusCode
          setErrorResponse(
            "Something went wrong. We're already working to make everything work again. Please try again later."
          );
        }

        if (res?.statusCode === 200) {
          const stringDate = formatDate(res.data?.data.birthDate!);
          setFormData({
            firstName: res?.data?.data.firstName ?? "",
            lastName: res?.data?.data.lastName ?? "",
            bio: res?.data?.data.bio ?? "",
            birthDate: stringDate ?? "",
            phoneNumber: res?.data?.data.phoneNumber ?? "",
            price: res?.data?.data.price ?? 0,
            linkedInProfileUrl: res?.data?.data.linkedInProfileUrl ?? "",
          });
        }
      });

    setImageUrl(accountService.getProfilePictureUrl());
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFormData: React.Dispatch<React.SetStateAction<UpdateMentorProfileModel>>
  ) => {
    setSuccessMsg("");
    setErrorResponse("");
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleAvatarChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files && event.target.files[0];
    // set file as a avatar picture here
    setImageUrl(URL.createObjectURL(file as Blob));
    const formData = new FormData();
    formData.append("img", file as Blob);

    setFileForm(formData);
  };

  const handleTextAreaChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    setFormData: React.Dispatch<React.SetStateAction<UpdateMentorProfileModel>>
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    setErrorResponse("");
    setSuccessMsg("");

    let model = formData;
    if (model.price?.toString() === "") {
      model.price = 0;
    }

    var res = await accountService.updateMentorAccountInfo(model);
    if (res?.statusCode && res.statusCode >= 400 && res.statusCode < 500) {
      setErrorResponse(res.errorMessage);
      return;
    }

    if (res?.statusCode && res.statusCode >= 500) {
      setErrorResponse(
        "Something went wrong. We're already working to make everything work again. Please try again later."
      );
      return;
    }

    // Send the avatar file to the backend
    if (fileForm) {
      var result = await accountService.updateProfilePicture(fileForm);
      if (result?.statusCode === 200) {
        setSuccessMsg("Your profile picture updated successfully!");
      } else if (
        result?.statusCode &&
        result.statusCode >= 400 &&
        result.statusCode < 500
      ) {
        // Use optional chaining with statusCode
        setErrorResponse(result.errorMessage);
        return;
      } else {
        setErrorResponse(
          "Something went wrong. We're already working to make everything work again. Please try again later."
        );
        return;
      }
    }

    setSuccessMsg("Your profile updated successfully!");
  };

  function formatDate(dateString: string | null): string | null {
    if (dateString === null) {
      return null;
    }
    var date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Change an account information</title>
      </Helmet>
      <CommonLayout role={localStorage.getItem("role")!}>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Account information</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-shrink-0 flex items-start">
              <div className="relative rounded-full overflow-hidden flex">
                <Avatar sizeClass="w-32 h-32" imgUrl={imageUrl} />
                <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="mt-1 text-xs">Change Image</span>
                </div>
                <input
                  type="file"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  name="profilePicture"
                  onChange={handleAvatarChange}
                />
              </div>
            </div>
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
              <div>
                <Label>First Name</Label>
                <Input
                  className="mt-1.5"
                  name="firstName"
                  value={formData.firstName ?? ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(e, setFormData)
                  }
                />
              </div>
              <div>
                <Label>Last Name</Label>
                <Input
                  className="mt-1.5"
                  name="lastName"
                  value={formData.lastName ?? ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(e, setFormData)
                  }
                />
              </div>
              <div className="max-w-lg">
                <Label>Date of birth</Label>
                <Input
                  className="mt-1.5"
                  type="date"
                  name="birthDate"
                  defaultValue={formData.birthDate ?? ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleInputChange(e, setFormData);
                  }}
                />
              </div>
              <div>
                <Label>Phone number</Label>
                <Input
                  type="tel"
                  className="mt-1.5"
                  name="phoneNumber"
                  value={formData.phoneNumber ?? ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(e, setFormData)
                  }
                />
              </div>
              {/* ---- */}
              <div>
                <Label>About you</Label>
                <Textarea
                  className="mt-1.5"
                  name="bio"
                  value={formData.bio ?? ""}
                  onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    handleTextAreaChange(e, setFormData);
                  }}
                />
              </div>
              <div>
                <Label>Price for a session</Label>
                <MoneyInput // Dollar sign too far to the left
                  type="number"
                  className="mt-1.5"
                  name="price"
                  value={formData.price ?? ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(e, setFormData)
                  }
                />
              </div>
              <div>
                <Label>Linkedin profile url</Label>
                <Input
                  className="mt-1.5"
                  name="linkedInProfileUrl"
                  value={formData.linkedInProfileUrl ?? ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(e, setFormData)
                  }
                />
              </div>
              {errorResponse !== "" ? (
                <span className="block text-center text-red-500 dark:text-red-500">
                  {errorResponse}
                </span>
              ) : null}
              {successMsg !== "" ? (
                <span className="block text-center text-green-500 dark:text-green-500">
                  {successMsg}
                </span>
              ) : null}
              <div className="pt-2">
                <ButtonPrimary
                  disabled={
                    formData.firstName === "" ||
                    formData.lastName === "" ||
                    formData.birthDate === "" ||
                    formData.phoneNumber === "" ||
                    formData.bio === "" ||
                    formData.price === 0 ||
                    formData.linkedInProfileUrl === ""
                  }
                  onClick={handleSubmit}
                >
                  Update info
                </ButtonPrimary>
                <span className="mx-2"></span>
                <ButtonSecondary href="/mentor">Schedule</ButtonSecondary>
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default MentorAccountPage;
