import React from "react";
import Heading from "components/Heading/Heading";
import CommentListing from "components/CommentListing/CommentListing";

export interface SectionCommentsProps {
  className?: string;
  heading?: string;
  subHeading?: string;
}

const SectionComments: React.FC<SectionCommentsProps> = ({
  className = "",
  heading = "Customer Feedback",
  subHeading = "See what our customers have to say",
}) => {
  return (
    <div className={`nc-SectionSliederNewCategories ${className}`}>
      <Heading desc={subHeading}>{heading}</Heading>
      <CommentListing />
    </div>
  );
};

export default SectionComments;
