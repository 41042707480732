import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";
import { RequestConfig, RequestConfigFormData } from "./RequestConfig";
import { UpdateMentorProfileModel } from "models/ProfileModels/UpdateMentorProfileModel";
import { UpdateStudentProfileModel } from "models/ProfileModels/UpdateStudentProfileModel";
import { ErrorModel } from "models/ErrorModel";

interface ServiceResult<T> {
  data?: T;
  errorMessage?: string | undefined;
  statusCode?: number;
}

class AccountService {
  private api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_AUTH_API,
      timeout: 10000,
    });
  }

  public async getAccountInfo<T>(
    role: string
  ): Promise<ServiceResult<AxiosResponse<T>>> {
    try {
      const address = role === "student" ? "students" : "mentors";
      const response = await this.api.get<T>(
        `${address}/profile`,
        RequestConfig
      );

      return { data: response, statusCode: response.status };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // Axios error handling
        const axiosError = error as AxiosError<ErrorModel>;
        if (axiosError.response?.data.errors) {
          return {
            errorMessage: axiosError.response?.data.errors[0],
            statusCode: axiosError.response?.status,
          };
        }

        return {
          errorMessage: "Something went wrong",
          statusCode: axiosError.response?.status,
        };
      } else {
        // Other error handling
        const generalError = error as Error;
        console.error("General error:", generalError.message);
        return { errorMessage: generalError.message };
      }
    }
  }

  public getProfilePictureUrl(): string {
    return process.env.REACT_APP_AUTH_API + "/images/profile";
  }

  public getMentorProfilePictureUrl(profileId: string): string {
    return (
      process.env.REACT_APP_AUTH_API +
      "/images/mentors-profile?profileId=" +
      profileId
    );
  }

  public async updateProfilePicture<T>(
    data: FormData
  ): Promise<ServiceResult<AxiosResponse<T>>> {
    try {
      const response = await this.api.patch<T>(
        "images/profile-picture",
        data,
        RequestConfigFormData
      );

      return { data: response, statusCode: response.status };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // Axios error handling
        const axiosError = error as AxiosError<ErrorModel>;
        if (axiosError.response?.data.errors) {
          return {
            errorMessage: axiosError.response?.data.errors[0],
            statusCode: axiosError.response?.status,
          };
        }

        return {
          errorMessage: "Something went wrong",
          statusCode: axiosError.response?.status,
        };
      } else {
        // Other error handling
        const generalError = error as Error;
        console.error("General error:", generalError.message);
        return { errorMessage: generalError.message };
      }
    }
  }

  public async updateStudentAccountInfo(
    data: UpdateStudentProfileModel
  ): Promise<ServiceResult<AxiosResponse>> {
    try {
      const response = await this.api.put(
        "students/profile",
        JSON.stringify(data),
        RequestConfig
      );

      return { data: response, statusCode: response.status, errorMessage: "" };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // Axios error handling
        const axiosError = error as AxiosError<ErrorModel>;
        if (axiosError.response?.data.errors) {
          return {
            errorMessage: axiosError.response?.data.errors[0],
            statusCode: axiosError.response?.status,
          };
        }

        return {
          errorMessage: "Something went wrong",
          statusCode: axiosError.response?.status,
        };
      } else {
        // Other error handling
        const generalError = error as Error;
        console.error("General error:", generalError.message);
        return { errorMessage: generalError.message };
      }
    }
  }

  public async updateMentorAccountInfo(
    data: UpdateMentorProfileModel
  ): Promise<ServiceResult<AxiosResponse>> {
    try {
      const response = await this.api.put(
        "mentors/profile",
        data,
        RequestConfig
      );

      return { data: response, statusCode: response.status, errorMessage: "" };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // Axios error handling
        const axiosError = error as AxiosError<ErrorModel>;
        if (axiosError.response?.data.errors) {
          return {
            errorMessage: axiosError.response?.data.errors[0],
            statusCode: axiosError.response?.status,
          };
        }

        return {
          errorMessage: "Something went wrong",
          statusCode: axiosError.response?.status,
        };
      } else {
        // Other error handling
        const generalError = error as Error;
        console.error("General error:", generalError.message);
        return { errorMessage: generalError.message };
      }
    }
  }
}

const accountService = new AccountService();
export default accountService;
