import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { MentorCardData } from "data/types";
import { Link } from "react-router-dom";

export interface MentorCardProps {
  data?: MentorCardData;
}

const MentorCard: React.FC<MentorCardProps> = ({
  data = {
    id: "123",
    name: "John Smith",
    avatarLink: "",
    href: "",
    price: 100.0,
  },
}) => {
  const { id, name, avatarLink, href, price } = data;
  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={[avatarLink]}
          href={href}
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="mentor-card mentor-p-4 space-y-4">
        <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {"QA Engineer"}
          </span>
          <div className="flex items-center space-x-2">
            <h2 className={` font-medium capitalize text-lg`}>
              <span className="line-clamp-1">{name}</span>
            </h2>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
              30 minutes session
            </span>
          </span>
          <span className="text-base font-semibold">
            {price}$
            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
              /session
            </span>
          </span>
        </div>
      </div>
    );
  };
  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      <Link to={href}>{renderContent()}</Link>
    </div>
  );
};

export default MentorCard;
