import cardInformationJson from "./jsons/cardInformations.json";
import { CardInformationData } from "./types";

const LISTING = cardInformationJson.map((post, index): CardInformationData => {
  return {
    ...post,
    // id: `cardInformation_${index}_`,
  };
});

export default LISTING;
