import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";
import { SignUpMentorRequest } from "models/AuthModels/SignUpMentorRequest";
import { SignUpStudentRequest } from "models/AuthModels/SignUpStudentRequest";
import { LoginRequest } from "models/AuthModels/LoginRequest";
import { RequestConfig } from "./RequestConfig";
import { ErrorModel } from "models/ErrorModel";

interface ServiceResult<T> {
  data?: T;
  errorMessage?: string | undefined;
  statusCode?: number;
}

class AuthService {
  private api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_AUTH_API,
      timeout: 10000,
    });
  }

  public async login(
    data: LoginRequest
  ): Promise<ServiceResult<AxiosResponse>> {
    try {
      const response = await this.api.post("auth/login", data, RequestConfig);
      return { data: response, statusCode: response.status, errorMessage: "" };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // Axios error handling
        const axiosError = error as AxiosError<ErrorModel>;
        if (axiosError.response?.data.errors) {
          return {
            errorMessage: axiosError.response?.data.errors[0],
            statusCode: axiosError.response?.status,
          };
        }

        return {
          errorMessage: "Something went wrong",
          statusCode: axiosError.response?.status,
        };
      } else {
        // Other error handling
        const generalError = error as Error;
        console.error("General error:", generalError.message);
        return { errorMessage: generalError.message };
      }
    }
  }

  public async signupStudent(
    data: SignUpStudentRequest
  ): Promise<ServiceResult<AxiosResponse>> {
    try {
      const response = await this.api.post(
        "auth/register",
        data,
        RequestConfig
      );

      return { data: response, statusCode: response.status, errorMessage: "" };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // Axios error handling
        const axiosError = error as AxiosError<ErrorModel>;
        if (axiosError.response?.data.errors) {
          return {
            errorMessage: axiosError.response?.data.errors[0],
            statusCode: axiosError.response?.status,
          };
        }

        return {
          errorMessage: "Something went wrong",
          statusCode: axiosError.response?.status,
        };
      } else {
        // Other error handling
        const generalError = error as Error;
        return { errorMessage: generalError.message };
      }
    }
  }

  public async signupMentor(
    data: SignUpMentorRequest
  ): Promise<ServiceResult<AxiosResponse>> {
    try {
      const response = await this.api.post(
        "auth/register",
        data,
        RequestConfig
      );

      return { data: response, statusCode: response.status, errorMessage: "" };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // Axios error handling
        const axiosError = error as AxiosError<ErrorModel>;
        if (axiosError.response?.data.errors) {
          return {
            errorMessage: axiosError.response?.data.errors[0],
            statusCode: axiosError.response?.status,
          };
        }

        return {
          errorMessage: "Something went wrong",
          statusCode: axiosError.response?.status,
        };
      } else {
        // Other error handling
        const generalError = error as Error;
        return { errorMessage: generalError.message };
      }
    }
  }

  public async confirmEmail(
    code: string,
    email: string
  ): Promise<ServiceResult<AxiosResponse>> {
    try {
      const response = await this.api.post(
        "auth/verify-email",
        {
          email: email,
          token: code,
        },
        RequestConfig
      );
      return { data: response, statusCode: response.status, errorMessage: "" };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // Axios error handling
        const axiosError = error as AxiosError<ErrorModel>;
        if (axiosError.response?.data.errors) {
          return {
            errorMessage: axiosError.response?.data.errors[0],
            statusCode: axiosError.response?.status,
          };
        }

        return {
          errorMessage: "Something went wrong",
          statusCode: axiosError.response?.status,
        };
      } else {
        // Other error handling
        const generalError = error as Error;
        console.error("General error:", generalError.message);
        return { errorMessage: generalError.message };
      }
    }
  }

  public async forgotPassword(
    email: string
  ): Promise<ServiceResult<AxiosResponse>> {
    try {
      const response = await this.api.post(
        "auth/new-password",
        {
          email: email,
        },
        RequestConfig
      );
      return { data: response, statusCode: response.status, errorMessage: "" };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // Axios error handling
        const axiosError = error as AxiosError<ErrorModel>;
        if (axiosError.response?.data.errors) {
          return {
            errorMessage: axiosError.response?.data.errors[0],
            statusCode: axiosError.response?.status,
          };
        }

        return {
          errorMessage: "Something went wrong",
          statusCode: axiosError.response?.status,
        };
      } else {
        // Other error handling
        const generalError = error as Error;
        console.error("General error:", generalError.message);
        return { errorMessage: generalError.message };
      }
    }
  }

  public async resetPassword(
    newPassword: string,
    confirmPassword: string,
    hash: string
  ): Promise<ServiceResult<AxiosResponse>> {
    try {
      const response = await this.api.patch(
        "auth/new-password",
        {
          password: newPassword,
          confirmPassword: confirmPassword,
          hash: hash,
        },
        RequestConfig
      );
      return { data: response, statusCode: response.status, errorMessage: "" };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // Axios error handling
        const axiosError = error as AxiosError<ErrorModel>;
        if (axiosError.response?.data.errors) {
          return {
            errorMessage: axiosError.response?.data.errors[0],
            statusCode: axiosError.response?.status,
          };
        }

        return {
          errorMessage: "Something went wrong",
          statusCode: axiosError.response?.status,
        };
      } else {
        // Other error handling
        const generalError = error as Error;
        console.error("General error:", generalError.message);
        return { errorMessage: generalError.message };
      }
    }
  }

  public async bookCall(
    email: string,
    name: string,
    phone: string
  ): Promise<ServiceResult<AxiosResponse>> {
    try {
      const response = await this.api.post(
        "auth/book-call",
        {
          email: email,
          name: name,
          phone: phone,
        },
        RequestConfig
      );
      return { data: response, statusCode: response.status, errorMessage: "" };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // Axios error handling
        const axiosError = error as AxiosError<ErrorModel>;
        if (axiosError.response?.data.errors) {
          return {
            errorMessage: axiosError.response?.data.errors[0],
            statusCode: axiosError.response?.status,
          };
        }

        return {
          errorMessage: "Something went wrong",
          statusCode: axiosError.response?.status,
        };
      } else {
        // Other error handling
        const generalError = error as Error;
        console.error("General error:", generalError.message);
        return { errorMessage: generalError.message };
      }
    }
  }
}

const authService = new AuthService();
export default authService;
