import { set } from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import authService from "services/AuthService";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";

const ForgotPasswordForm: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [requestErrorMessage, setRequestErrorMessage] = useState<
    string | undefined
  >("");
  const [successMsg, setSuccessMsg] = useState<string>("");

  const forgotPassword = async (
    e: React.FormEvent<HTMLFormElement>,
    email: string
  ) => {
    e.preventDefault();
    setRequestErrorMessage("");
    setSuccessMsg("");
    const result = await authService.forgotPassword(email);
    if (result?.statusCode !== 200 && result?.statusCode! < 500) {
      setRequestErrorMessage(result?.errorMessage);
      return;
    }
    if (result?.statusCode! >= 500) {
      setRequestErrorMessage("Server error. Please try again later.");
      return;
    }
    if (result?.statusCode === 200) {
      setSuccessMsg("Password reset link sent to your email.");
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFormData: React.Dispatch<React.SetStateAction<string>>
  ) => {
    setFormData(e.target.value);
  };

  return (
    <div className="max-w-md mx-auto space-y-6">
      {/* FORM */}
      <form
        className="grid grid-cols-1 gap-6"
        onSubmit={async (e: React.FormEvent<HTMLFormElement>) =>
          await forgotPassword(e, email)
        }
      >
        <label className="block">
          <span className="text-neutral-800 dark:text-neutral-200">
            Email address
          </span>
          <Input
            type="email"
            name="email"
            placeholder="example@example.com"
            className="mt-1"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e, setEmail)
            }
          />
        </label>
        {requestErrorMessage !== "" ? (
          <span className="block text-center text-red-500 dark:text-red-500">
            {requestErrorMessage}
          </span>
        ) : null}
        {successMsg !== "" ? (
          <span className="block text-center text-green-500 dark:text-green-500">
            {successMsg}
          </span>
        ) : null}
        <ButtonPrimary type="submit" disabled={email === ""}>
          Reset Password
        </ButtonPrimary>
      </form>

      {/* ==== */}
      {/* <span className="block text-center text-neutral-700 dark:text-neutral-300">
        New user? {` `}
        <Link
          className="text-primary-500 hover:text-primary-700 dark:hover:text-primary-400"
          to="/signup"
        >
          Create an account
        </Link>
      </span> */}
    </div>
  );
};

export default ForgotPasswordForm;
