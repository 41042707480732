import { Tab } from "@headlessui/react";
import { v4 } from "uuid";
import StartRating from "components/StartRating/StartRating";
import React, { FC, Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SocialsList from "shared/SocialsList/SocialsList";
import { Helmet } from "react-helmet";
import accountService from "services/AccountService";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment-timezone";
import MentorCard from "components/MentorsComponent/MentorsCardComponent";
import { mentorsService } from "services/MentorsService";
import scheduleService from "services/ScheduleService";
import MentorAppointmentResponse from "models/ScheduleModels/MentorAppointment";
import {
  MentorAccount,
  MentorCardData,
  MentorPreviewProfile,
} from "models/MentorsModels/MentorAccount";
import DateHelper from "helpers/dateStringHelper";

export interface MentorInformationProps {
  className?: string;
}

const mLocalizer = momentLocalizer(moment);

const MentorInformationPage: FC<MentorInformationProps> = ({
  className = "",
}) => {
  let [categories] = useState(["QA"]);
  const { id } = useParams();
  const [imageUrl, setImageUrl] = useState<string | undefined>("");
  const [profile, setProfile] = useState<MentorAccount>({
    firstName: "",
    lastName: "",
    bio: "",
    price: 0,
    birthDate: "",
    linkedInProfileUrl: "",
  });
  const [errorResponse, setErrorResponse] = React.useState<
    string | undefined
  >();

  const [calendarEvents, setCalendarEvents] = useState<any[]>([]);
  const [mentors, setMentors] = useState<MentorCardData[]>([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorInModal, setErrorInModal] = useState<string>("");
  const [selectedSlot, setSelectedSlot] = useState<any>(null);

  const onSelectEvent = (slotInfo: any) => {
    const eventToBook = calendarEvents.find(
      (event) => event.id === slotInfo.id
    );
    setErrorInModal("");
    setSelectedSlot(eventToBook);
    setIsModalOpen(true);
  };

  useEffect(() => {
    mentorsService.getMentorById<MentorPreviewProfile>(id!).then((res) => {
      if (res?.statusCode && res.statusCode >= 400 && res.statusCode < 500) {
        // Use optional chaining with statusCode
        setErrorResponse(res.errorMessage);
      } else if (res?.statusCode && res.statusCode >= 500) {
        // Use optional chaining with statusCode
        setErrorResponse(
          "Something went wrong. We're already working to make everything work again. Please try again later."
        );
      }

      if (res?.statusCode === 200) {
        setProfile({
          firstName: res?.data?.data.firstName ?? "",
          lastName: res?.data?.data.lastName ?? "",
          birthDate: "",
          bio: res?.data?.data.bio ?? "",
          price: res?.data?.data.price ?? 0,
          linkedInProfileUrl: res?.data?.data.linkedInProfileUrl ?? "",
        });
      }
    });

    getMentorsAppointments(id!);
    mentorsService.getMentors<MentorPreviewProfile[]>(4).then((res) => {
      const mentorsCards: MentorCardData[] = (res.data?.data || []).map(
        (mentor) => ({
          id: mentor.id || "",
          name: `${mentor.firstName || ""} ${mentor.lastName || ""}`,
          avatarLink: accountService.getMentorProfilePictureUrl(mentor.id), // Replace with actual avatar link
          href: `/mentors/${mentor.id}`, // Replace with actual href link
          price: mentor.price || 0,
        })
      );
      setMentors(mentorsCards);
    });

    setImageUrl(accountService.getMentorProfilePictureUrl(id!));
  }, []);

  const renderNameOrRole = () => {
    if (profile.firstName !== "" && profile.lastName !== "") {
      return profile.firstName + " " + profile.lastName;
    }

    return localStorage.getItem("role") === "mentor" ? "Mentor" : "Student";
  };

  //
  const getMentorsAppointments = (id: string) => {
    scheduleService
      .getMentorsAppointments<MentorAppointmentResponse>(id!)
      .then((res) => {
        let timeSlotsAvailable = res.data!.data.slots.filter(
          (slot) => !slot.availability
        );

        var timeSlots = timeSlotsAvailable.map((slot) => {
          var date = moment(slot.time).toDate();
          var date2 = moment(slot.time)
            .toDate()
            .setMinutes(date.getMinutes() + 30);

          return {
            id: v4(),
            title: DateHelper.formatTimeTo12Hour(
              moment(slot.time).format("HH:mm")
            ),
            start: date,
            end: date2,
          };
        });

        setCalendarEvents(timeSlots);
      });
  };

  const renderSidebar = () => {
    return (
      <div className=" w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
        <Avatar
          hasChecked
          hasCheckedClass="w-6 h-6 -top-0.5 right-2"
          sizeClass="w-28 h-28"
          imgUrl={imageUrl}
        />

        {/* ---- */}
        <div className="space-y-3 text-center flex flex-col items-center">
          <h2 className="text-3xl font-semibold">
            {profile.firstName + " " + profile.lastName}
          </h2>
          <span className="text-neutral-500 dark:text-neutral-400">
            QA Engineer
          </span>
          <StartRating className="!text-base" />
          <p className="text-neutral-500 dark:text-neutral-400">
            Session 30 Min{" "}
          </p>
        </div>

        {/* ---- */}
        <p className="text-neutral-500 dark:text-neutral-400">{profile.bio}</p>

        {/* ---- */}
        <SocialsList
          className="!space-x-3"
          itemClass="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
          socials={[
            {
              name: "LinkedIn",
              icon: "lab la-linkedin",
              href: profile.linkedInProfileUrl,
            },
          ]}
        />

        {/* ---- */}
        <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div>

        {/* ---- */}
        <div className="space-y-4">
          {/* <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              Ha Noi, Viet Nam
            </span>
          </div>
          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              Speaking English
            </span>
          </div>

          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              Joined in March 2016
            </span>
          </div> */}
          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              Price: {profile.price}$
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">
            {renderNameOrRole()}'s schedule
          </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Here you can schedule a meeting with this mentor.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Calendar
            selectable
            localizer={mLocalizer}
            defaultDate={new Date()}
            defaultView={Views.WEEK}
            views={[Views.DAY, Views.WEEK]}
            events={calendarEvents}
            onSelectEvent={onSelectEvent}
          ></Calendar>
        </div>
        <div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-secondary-900 text-secondary-50 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {mentors
                    .filter((_, i) => i < 4)
                    .map((mentor) => (
                      <MentorCard key={mentor.id} data={mentor} />
                    ))}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary href="/mentors-list">
                    Show me more
                  </ButtonSecondary>
                </div>
              </Tab.Panel>
              {/* <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {DEMO_EXPERIENCES_LISTINGS.filter((_, i) => i < 4).map(
                    (stay) => (
                      <ExperiencesCard key={stay.id} data={stay} />
                    )
                  )}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel> */}
              {/* <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {DEMO_CAR_LISTINGS.filter((_, i) => i < 4).map((stay) => (
                    <CarCard key={stay.id} data={stay} size="small" />
                  ))}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary href="/mentors">
                    Show me more
                  </ButtonSecondary>
                </div>
              </Tab.Panel> */}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  const ConfirmationModal: React.FC = () => {
    if (!selectedSlot) return null;

    const handleYes = async () => {
      let result = await scheduleService.createMentorsAppointment({
        mentorProfileId: id!,
        date: selectedSlot.start,
        timeZoneId: moment.tz.guess(),
      });

      if (result.statusCode === 200) {
        await getMentorsAppointments(id!);
        setIsModalOpen(false);
      }

      if (result.statusCode! >= 400 && result.statusCode! < 500) {
        setErrorInModal(result.errorMessage!);
        return;
      }

      setErrorInModal("Something went wrong. Please try again later.");
    };

    const handleNo = () => {
      setIsModalOpen(false);
    };

    return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white p-4 rounded-lg shadow-md w-96">
          <p className="mb-4">
            Are you sure to reserve a session at{" "}
            {new Date(selectedSlot.start).toLocaleDateString()}?
          </p>
          {errorInModal && <p className="text-red-500 mb-4">{errorInModal}</p>}
          <div className="flex justify-end space-x-4">
            <button onClick={handleNo} className="px-4 py-2 text-gray-600">
              Cancel
            </button>
            <button
              onClick={handleYes}
              className="px-4 py-2 bg-blue-600 text-white rounded"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>QA-Offer || Book your session</title>
      </Helmet>
      <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
          {isModalOpen && <ConfirmationModal />}
          {/* Rest of your component */}
        </div>
        <div className="block flex-grow mb-24 lg:mb-0">
          <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
        </div>
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
          {renderSection1()}
        </div>
      </main>
    </div>
  );
};

export default MentorInformationPage;
