class DateHelper {
  static formatDate(dateString: string | null): string | null {
    if (dateString === null) {
      return null;
    }
    var date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  // format time string as 10:00 AM to 24 hour moment time
  static formatTime(time: string | null): string | null {
    if (time === null) {
      return null;
    }

    var timeAndMeridiam = time.split(" ");

    var hoursAndMinutes = timeAndMeridiam[0].split(":");
    if (timeAndMeridiam[1] === "PM") {
      if (hoursAndMinutes[0] !== "12") {
        hoursAndMinutes[0] = String(Number(hoursAndMinutes[0]) + 12);
      }
      hoursAndMinutes[0] = String(Number(hoursAndMinutes[0]));
    } else if (timeAndMeridiam[1] === "AM" && hoursAndMinutes[0] === "12") {
      hoursAndMinutes[0] = "00";
    }

    return `${hoursAndMinutes[0]}:${hoursAndMinutes[1]}`;
  }

  // format time string as 13:00:00 to 12 hour moment time as 01:00 PM
  static formatTimeTo12Hour(time: string | null): string | null {
    if (time === null) {
      return null;
    }

    var hoursAndMinutes = time.split(":");
    var hours = Number(hoursAndMinutes[0]);
    var minutes = Number(hoursAndMinutes[1]);

    var meridiam = "AM";
    if (hours > 12) {
      hours = hours - 12;
      meridiam = "PM";
    }

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )} ${meridiam}`;
  }
}

const dateHelper = new DateHelper();
export default DateHelper;
