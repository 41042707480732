import React, { FC } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import CurrencyDropdown from "./CurrencyDropdown";
import AvatarDropdown from "./AvatarDropdown";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  // const [role, setRole] = React.useState<string | null>(
  //   localStorage.getItem("role")
  // );
  // const [name, setName] = React.useState("");

  // React.useEffect(() => {
  //   const name = localStorage.getItem("name");
  //   if (role) {
  //     setRole(role === "mentor" ? "Mentor" : "Student");
  //   }
  //   if (name) {
  //     setName(name);
  //   }
  // }, []);

  // Check if LOGIN_SUCCESS event was dispatched
  // React.useEffect(() => {
  //   const event = new Event("LOGIN_SUCCESS");
  //   window.addEventListener("LOGIN_SUCCESS", () => {
  //     const role = localStorage.getItem("role");
  //     const name = localStorage.getItem("name");
  //     if (role) {
  //       setRole(role === "mentor" ? "Mentor" : "Student");
  //     }
  //     if (name) {
  //       setName(name);
  //     }
  //   });
  //   window.dispatchEvent(event);
  // }, []);

  const loggedRender = () => {
    return (
      <div className="hidden xl:flex items-center space-x-0.5">
        <SwitchDarkMode />
        <div className="px-1" />
        {/* <div>Hello {name === "" ? role : name}!</div>
        <div className="px-1" />
        <AvatarDropdown role={role} /> */}
      </div>
    );
  };
  const unloggerRender = () => {
    return (
      <div className="hidden xl:flex items-center space-x-0.5">
        <SwitchDarkMode />
        <div className="px-1" />
        {/* <ButtonPrimary href="/signup">Sign Up</ButtonPrimary>
        <div className="px-1" />
        <ButtonPrimary href="/login">Sign In</ButtonPrimary> */}
      </div>
    );
  };

  return (
    // <div className={`nc-MainNav1 relative z-10 ${className}`}>
    //   <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
    //     <div className="hidden md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
    //       <Logo />
    //       {/* <Navigation /> */}
    //     </div>

    //     <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
    //       {/* <HeroSearchForm2MobileFactory /> */}
    //       <MenuBar />
    //     </div>

    //     <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
    //       <div className="hidden xl:flex items-center space-x-0.5">
    //         <SwitchDarkMode />
    //         {/* <SearchDropdown /> */}
    //         <div className="px-1" />
    //         <ButtonPrimary href="/signup">Sign up</ButtonPrimary>
    //       </div>
    //       <div className="flex xl:hidden items-center">
    //         <SwitchDarkMode />
    //         <div className="px-0.5" />
    //         <MenuBar />
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
          <Logo />
        </div>

        <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          <MenuBar />
        </div>

        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          {/* {role === "" || role === null ? unloggerRender() : loggedRender()} */}
          {unloggerRender()}
          <div className="flex xl:hidden items-center">
            <SwitchDarkMode />
            <div className="px-0.5" />
            <MenuBar />
          </div>
        </div>

        <div className="flex lg:hidden items-center space-x-2">
          <SwitchDarkMode />
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
