import CommentListing from "components/CommentListing/CommentListing";
import StartRating from "components/StartRating/StartRating";
import React, { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SocialsList from "shared/SocialsList/SocialsList";
import { Helmet } from "react-helmet";
import accountService from "services/AccountService";
import scheduleService from "services/ScheduleService";
import { MentorAccountInfoResponse } from "models/AccountModels/AccountInfoResponse";
import DateHelper from "helpers/dateStringHelper";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { MentorAccount } from "models/MentorsModels/MentorAccount";
import { SocialType } from "shared/SocialsShare/SocialsShare";
import { MentorAppointmentsResponse } from "models/ScheduleModels/StudentAppontmentsModels";
import { v4 } from "uuid";

export interface AuthorPageProps {
  className?: string;
}

const mLocalizer = momentLocalizer(moment);

function createSocialLinks(link: string) {
  var d: SocialType[] = [
    { name: "Linkedin", icon: "lab la-linkedin", href: `${link}` },
  ];
  return d;
}

const MentorMainPage: FC<AuthorPageProps> = ({ className = "" }) => {
  const navigate = useNavigate();

  const [imageUrl, setImageUrl] = useState<string | undefined>("");
  const [profile, setProfile] = useState<MentorAccount>({
    firstName: "",
    lastName: "",
    birthDate: "",
    bio: "",
    price: 50,
    linkedInProfileUrl: "",
  });
  const [appointments, setAppointments] = useState<any[]>([]);
  const [errorResponse, setErrorResponse] = React.useState<
    string | undefined
  >();

  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role === null) {
      navigate("/login");
      return;
    } else if (role !== "mentor") {
      navigate("/student");
      return;
    }
    accountService
      .getAccountInfo<MentorAccountInfoResponse>("mentor")
      .then((res) => {
        if (res?.statusCode && res.statusCode >= 400 && res.statusCode < 500) {
          // Use optional chaining with statusCode
          setErrorResponse(res.errorMessage);
        } else if (res?.statusCode && res.statusCode >= 500) {
          // Use optional chaining with statusCode
          setErrorResponse(
            "Something went wrong. We're already working to make everything work again. Please try again later."
          );
        }

        if (res?.statusCode === 200) {
          const stringDate = DateHelper.formatDate(res.data?.data.birthDate!);
          setProfile({
            firstName: res?.data?.data.firstName ?? "",
            lastName: res?.data?.data.lastName ?? "",
            birthDate: stringDate ?? "",
            bio: res?.data?.data.bio ?? "",
            price: res?.data?.data.price ?? 20,
            linkedInProfileUrl: res?.data?.data.linkedInProfileUrl ?? "",
          });
        }
      });

    getMentorsAppointments();

    setImageUrl(accountService.getProfilePictureUrl());
  }, []);

  const getMentorsAppointments = async () => {
    scheduleService
      .getStudentAppointments<MentorAppointmentsResponse>(
        localStorage.getItem("role")!
      )
      .then((res) => {
        if (res?.statusCode && res.statusCode >= 400 && res.statusCode < 500) {
          // Use optional chaining with statusCode
          setErrorResponse(res.errorMessage);
        } else if (res?.statusCode && res.statusCode >= 500) {
          // Use optional chaining with statusCode
          setErrorResponse(
            "Something went wrong. We're already working to make everything work again. Please try again later."
          );
        }

        if (res?.statusCode === 200) {
          var timeSlots = res.data?.data.items.map((slot) => {
            var date = moment(slot.startDateTime).toDate();
            var date2 = moment(slot.startDateTime)
              .toDate()
              .setMinutes(date.getMinutes() + 30);

            return {
              id: v4(),
              title: DateHelper.formatTimeTo12Hour(
                moment(slot.startDateTime).format("HH:mm")
              ),
              start: date,
              end: date2,
              information: slot.studentName,
            };
          });

          setAppointments(timeSlots || []);
        }
      });
  };

  const renderSidebar = () => {
    return (
      <div className=" w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
        <Avatar
          hasChecked
          hasCheckedClass="w-6 h-6 -top-0.5 right-2"
          sizeClass="w-28 h-28"
          imgUrl={imageUrl}
        />

        {/* ---- */}
        <div className="space-y-3 text-center flex flex-col items-center">
          <h2 className="text-3xl font-semibold">
            {profile.firstName + " " + profile.lastName}
          </h2>
          <StartRating className="!text-base" />
        </div>

        {/* ---- */}
        <p className="text-neutral-500 dark:text-neutral-400">{profile.bio}</p>

        {/* ---- */}
        <SocialsList
          className="!space-x-3"
          itemClass="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
          socials={createSocialLinks(profile.linkedInProfileUrl)}
        />

        {/* ---- */}
        <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div>

        {/* ---- */}
        <div className="space-y-4">
          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              Price: {profile.price}$
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">
            {profile.firstName === "" || profile.lastName === ""
              ? "Mentor's schedule"
              : `${profile.firstName + " " + profile.lastName}'s schedule`}
          </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Here you can find your planned schedule.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Calendar
            selectable
            localizer={mLocalizer}
            views={[Views.DAY, Views.WEEK]}
            defaultDate={new Date()}
            defaultView={Views.DAY}
            events={appointments}
            onSelectEvent={onSelectEvent}
          ></Calendar>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing hasListingTitle className="pb-8" />
          <CommentListing hasListingTitle className="py-8" />
          <CommentListing hasListingTitle className="py-8" />
          <CommentListing hasListingTitle className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState<any>(null);

  const onSelectEvent = (slotInfo: any) => {
    const eventToBook = appointments.find((event) => event.id === slotInfo.id);
    setSelectedSlot(eventToBook);
    setIsModalOpen(true);
  };

  const ConfirmationModal: React.FC = () => {
    if (!selectedSlot) return null;

    const handleCancel = async () => {
      let result = await scheduleService.cancelAppointment({
        date: selectedSlot.start,
        timezoneId: moment.tz.guess(),
      });

      if (result.statusCode === 200) {
        await getMentorsAppointments();
      }

      setIsModalOpen(false);
    };

    const handleClose = () => {
      setIsModalOpen(false);
    };

    return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white p-4 rounded-lg shadow-md w-96">
          <p className="mb-4">
            You booked a meeting with {selectedSlot.information} at{" "}
            {moment(selectedSlot.start).format("HH:mm")}. You can cancel it by
            clicking Cancel button
          </p>
          <div className="flex justify-end space-x-4">
            <button onClick={handleClose} className="px-4 py-2 text-gray-600">
              Close
            </button>
            <button
              onClick={handleCancel}
              className="px-4 py-2 bg-red-600 text-white rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>QA-Offer</title>
      </Helmet>
      <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
          {isModalOpen && <ConfirmationModal />}
          {/* Rest of your component */}
        </div>
        <div className="block flex-grow mb-24 lg:mb-0">
          <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
        </div>
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
          {renderSection1()}
          {/* {renderSection2()} */}
        </div>
      </main>
    </div>
  );
};

export default MentorMainPage;
