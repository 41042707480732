import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/hero-right.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import { SectionHeroProps } from "./SectionHero";
import authService from "services/AuthService";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";

interface BookCallRequest {
  name: string;
  email: string;
  phone: string;
}

const SectionHeroForm: FC<SectionHeroProps> = ({ className = "" }) => {
  const [formData, setFormData] = React.useState<BookCallRequest>({
    name: "",
    email: "",
    phone: "",
  });

  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [requestErrorMessage, setRequestErrorMessage] = React.useState<
    string | undefined
  >("");
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFormData: React.Dispatch<React.SetStateAction<BookCallRequest>>
  ) => {
    setRequestErrorMessage("");
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const bookCall = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setRequestErrorMessage("");
    const result = await authService.bookCall(
      formData.email,
      formData.name,
      formData.phone
    );

    if (result?.statusCode !== 200 && result?.statusCode! < 500) {
      setRequestErrorMessage(result?.errorMessage);
      return;
    }

    if (result?.statusCode! >= 500) {
      setRequestErrorMessage("Something went wrong. Please try again later.");
      return;
    }

    openSuccessModal();
  };

  const openSuccessModal = () => {
    setModalOpen(true);
  };

  const closeSuccessModal = () => {
    setModalOpen(false);
  };

  const renderSuccessModal = () => {
    return (
      <div
        className={`fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-opacity-50 ${
          modalOpen ? "" : "hidden"
        }`}
      >
        <div className="relative w-full max-w-md glassmorphism text-base text-neutral-200 dark:text-neutral-200 rounded-md">
          <div className="flex flex-col items-center justify-center w-full h-full p-8 space-y-8">
            <h2 className="text-2xl font-medium text-center text-neutral-900 dark:text-neutral-100">
              Congratulations!
            </h2>
            <span className="text-base md:text-lg text-neutral-700 dark:text-neutral-400 text-center">
              Your consultation has been booked successfully. Our career
              consultant will be in touch with you shortly.
            </span>
            <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
              <ButtonPrimary onClick={closeSuccessModal}>Close</ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    );
    // use different colors if dark theme was chosen
    // return (
    //   <div
    //     className={`fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-opacity-50 ${
    //       modalOpen ? "" : "hidden"
    //     }`}
    //   >
    //     <div className="relative w-full max-w-md glassmorphism bg-neutral-100 text-base dark:bg-neutral-800 text-neutral-900 dark:text-neutral-200 rounded-md">
    //       <div className="flex flex-col items-center justify-center w-full h-full p-8 space-y-8">
    //         <h2 className="text-2xl font-medium text-center text-neutral-900 dark:text-neutral-100">
    //           Congratulations!
    //         </h2>
    //         <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400 text-center">
    //           Your consultation has been booked successfully. Our career
    //           consultant will be in touch with you shortly.
    //         </span>
    //         <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
    //           <ButtonPrimary onClick={closeSuccessModal}>Close</ButtonPrimary>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // );
    // return (
    //   <div
    //     className={`fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50 ${
    //       modalOpen ? "" : "hidden"
    //     }`}
    //   >
    //     <div className="relative w-full max-w-md bg-white rounded-md">
    //       <div className="flex flex-col items-center justify-center w-full h-full p-8 space-y-8">
    //         <h2 className="text-2xl font-medium text-center">
    //           Your request has been sent successfully!
    //         </h2>
    //         <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
    //           <ButtonPrimary onClick={closeSuccessModal}>Close</ButtonPrimary>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // );
  };

  return (
    <div
      id="booking-form"
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      {modalOpen ? renderSuccessModal() : null}
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-0 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl !leading-[114%] ">
            Interested?
          </h2>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            Leave your email and number in the form and we'll be in touch to
            arrange a free consultation with an experienced career consultant
          </span>
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
            {/* <ButtonPrimary href="/signup">
              Book a free consultation
            </ButtonPrimary> */}
            {/* <ButtonPrimary href="/signup-mentors">
              Become a mentor
            </ButtonPrimary> */}
          </div>
        </div>
        <div className="flex-grow hero-form">
          {/* <img className="w-full" src={imagePng} alt="hero" /> */}
          {/* <form className="flex flex-col space-y-4 w-full"> */}
          <form
            className="flex-shrink-0 flex flex-col space-y-8 sm:space-y-10 pb-0 lg:pb-0 xl:pr-14 lg:mr-10 xl:mr-0"
            onSubmit={bookCall}
          >
            <div className="flex flex-col space-y-2">
              <label className="text-sm font-medium">Name</label>
              <input
                name="name"
                type="text"
                className="border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent"
                value={formData.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e, setFormData)
                }
              />
            </div>
            <div className="flex flex-col space-y-2">
              <label className="text-sm font-medium">Email</label>
              <input
                name="email"
                type="email"
                className="border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent"
                value={formData.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e, setFormData)
                }
              />
            </div>
            <div className="flex flex-col space-y-2">
              <label className="text-sm font-medium">Phone</label>
              <input
                name="phone"
                type="number"
                className="border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent"
                value={formData.phone}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e, setFormData)
                }
              />
            </div>
            {/* <div className="flex flex-col space-y-2">
              <label className="text-sm font-medium">Message</label>
              <textarea className="border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent"></textarea>
            </div> */}
            <div className="flex flex-col space-y-2">
              <ButtonPrimary
                disabled={
                  formData.name === "" ||
                  formData.email === "" ||
                  formData.phone === ""
                }
                type="submit"
              >
                Book a free consultation
              </ButtonPrimary>
            </div>
            <div className="flex flex-col space-y-2">
              <span className="text-sm font-medium text-red-500">
                {requestErrorMessage}
              </span>
            </div>
          </form>
        </div>
      </div>

      {/* <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
        <HeroSearchForm />
      </div> */}
    </div>
  );
};

export default SectionHeroForm;
