import StartRating from "components/StartRating/StartRating";
import Avatar from "shared/Avatar/Avatar";
import SocialsList from "shared/SocialsList/SocialsList";
import DenisImage from "images/Denis.png";

const DenisComponent: React.FC = () => {
  return (
    <div className="flex flex-col sm:flex-row w-full h-full sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 px-0 sm:p-6 xl:p-8">
      {/* Left Column */}
      <div className="flex flex-col items-center text-center space-y-6 w-full sm:w-1/2">
        <Avatar
          hasChecked
          hasCheckedClass="w-6 h-6 -top-0.5 right-2"
          sizeClass="w-28 h-28"
          imgUrl={DenisImage}
        />

        <div className="space-y-3">
          <h2 className="text-2xl sm:text-3xl font-semibold">Denis Danshin</h2>
          <StartRating className="justify-center !text-base" point={4.9} />
        </div>

        <SocialsList
          socials={[
            {
              name: "Linkedin",
              icon: "lab la-linkedin",
              href: "https://www.linkedin.com/in/denis-danshin/",
            },
          ]}
          className="!space-x-3"
          itemClass="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
        />

        <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div>

        <div className="">
          <div className="flex justify-center text-center space-x-4">
            <span className="text-neutral-400 dark:text-neutral-400">
              CEO at QA Offer
            </span>
          </div>
          <div className="flex justify-center items-center space-x-4">
            <span className="text-neutral-400 dark:text-neutral-400">
              Speak English, Russian
            </span>
          </div>

          <div className="flex justify-center items-center space-x-4">
            <span className="text-neutral-400 dark:text-neutral-400">
              Mentoring since 2016
            </span>
          </div>
        </div>
      </div>

      {/* Right Column */}
      <div className="flex flex-col items-center sm:items-start text-center sm:text-left space-y-6 w-full sm:w-1/2 sm:pt-4 pt-6">
        {/* <h2 className="text-xl font-semibold">Additional Information</h2> */}
        <p className="text-2xl sm:text-3xl text-neutral-600 dark:text-neutral-300">
          I have been working as a Software QA Engineer / Consultant for more
          than 7 years, during my mentorship career, over 350 of students
          successfully found competitive positions in US, Canada, Belarus,
          Kazakhstan etc. I would be happy to find an experienced mentor for you
          or help you by myself: prepare your resume, conduct a mock interview
          and prepare you for an interview with any company.
        </p>
        {/* Add more content here as needed */}
      </div>
    </div>
  );
};

export default DenisComponent;
