import React, { FC, useState } from "react";
import { StarIcon } from "@heroicons/react/24/solid";
import Avatar from "shared/Avatar/Avatar";
import comments from "data/jsons/__comments.json";

interface CommentListingDataType {
  name: string;
  avatar?: string;
  date: string;
  comment: string;
  starPoint: number;
}

export interface CommentListingProps {
  className?: string;
  data?: CommentListingDataType[];
  hasListingTitle?: boolean;
}

const DEMO_DATA: CommentListingDataType[] = comments.map(
  (comment): CommentListingDataType => ({
    name: comment.author,
    comment: comment.content,
    starPoint: Math.floor(Math.random() * 5) + 1,
    date: comment.date,
  })
);

const CommentListing: FC<CommentListingProps> = ({
  className = "",
  data = DEMO_DATA,
  hasListingTitle,
}) => {
  const [displayedComments, setDisplayedComments] = useState<
    CommentListingDataType[]
  >(DEMO_DATA.sort((x) => x.comment.length).slice(0, 3));
  const [isAllCommentsDisplayed, setIsAllCommentsDisplayed] =
    useState<boolean>(false);

  const loadMoreComments = () => {
    setDisplayedComments(
      isAllCommentsDisplayed
        ? DEMO_DATA.sort((x) => x.comment.length).slice(0, 3)
        : DEMO_DATA.sort((x) => x.comment.length)
    );
    setIsAllCommentsDisplayed(!isAllCommentsDisplayed);
  };

  return (
    <div
      className={`nc-CommentListing ${className}`}
      data-nc-id="CommentListing"
    >
      {/* Replaced columnCount with grid layout for responsiveness */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {displayedComments.map((commentData, index) => (
          <div
            key={index}
            className="break-inside-avoid mb-4 p-4 border rounded-lg"
          >
            {/* Comment content */}
            <div className="mt-3">
              <div className="text-sm font-semibold text-center">
                {commentData.name}
              </div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 block text-center">
                {commentData.date}
              </span>
            </div>
            <div className="mt-3">
              <div dangerouslySetInnerHTML={{ __html: commentData.comment }} />
            </div>
          </div>
        ))}
      </div>
      <div className="text-center mt-4">
        <button
          onClick={loadMoreComments}
          className="py-2 px-4 border rounded-md text-sm font-medium"
        >
          {!isAllCommentsDisplayed ? "Load More" : "Show Less"}
        </button>
      </div>
    </div>
  );
};

export default CommentListing;
