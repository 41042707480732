import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  // {
  //   id: "5",
  //   title: "Getting started",
  //   menus: [
  //     { href: "#", label: "Installation" },
  //     { href: "#", label: "Release Notes" },
  //     { href: "#", label: "Upgrade Guide" },
  //     { href: "#", label: "Browser Support" },
  //     { href: "#", label: "Editor Support" },
  //   ],
  // },
  {
    id: "1",
    title: "Contact us",
    menus: [
      {
        href: "mailto:danshindenis020@gmail.com",
        label: "Email: danshindenis020@gmail.com",
      },
      { href: "tel:+12132717664", label: "Phone: +1 213-271-7664" },
      // { href: "#", label: "Design systems" },
      // { href: "#", label: "Pricing" },
      // { href: "#", label: "Security" },
    ],
  },
  // {
  //   id: "2",
  //   title: "Resources",
  //   menus: [
  //     { href: "#", label: "Best practices" },
  //     { href: "#", label: "Support" },
  //     { href: "#", label: "Developers" },
  //     { href: "#", label: "Learn design" },
  //     { href: "#", label: "Releases" },
  //   ],
  // },
  // {
  //   id: "4",
  //   title: "Community",
  //   menus: [
  //     { href: "#", label: "Discussion Forums" },
  //     { href: "#", label: "Code of Conduct" },
  //     { href: "#", label: "Community Resources" },
  //     { href: "#", label: "Contributing" },
  //     { href: "#", label: "Concurrent Mode" },
  //   ],
  // },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="mb-4 p-4 rounded-lg">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index} className="whitespace-nowrap">
              <a
                className="text-neutral-600 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-8 lg:py-8 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container mx-auto grid grid-cols-12 gap-10 px-4 sm:px-6 lg:px-8">
        {/* Logo Column, made much smaller by using col-span-1 out of 12 */}
        <div className="col-span-12 md:col-span-2 flex justify-center md:justify-start">
          <div className="mt-4">
            <Logo />
          </div>
          {/* Additional elements for the first column, if necessary */}
        </div>

        {/* Contact Information and Menus Column, taking up the majority of the space */}
        <div className="col-span-12 md:col-span-10 flex flex-col space-y-10">
          {widgetMenus.map(renderWidgetMenuItem)}
        </div>
      </div>
    </div>
  );
};

export default Footer;
