import React, { FC } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import authService from "services/AuthService";
import { SignUpStudentRequest } from "models/AuthModels/SignUpStudentRequest";

export interface PageSignUpProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const [formData, setFormData] = React.useState<SignUpStudentRequest>({
    email: "",
    password: "",
    confirmPassword: "",
    role: "student",
    firstName: "",
    lastName: "",
    phoneNumber: "",
  });

  const [showSignUpBlock, setShowSignUpBlock] = React.useState<boolean>(true);

  const [confirmationCode, setConfirmationCode] = React.useState<string>("");

  const [requestErrorMessage, setRequestErrorMessage] = React.useState<
    string | undefined
  >("");
  const [confirmationCodeErrorMessage, setConfirmationCodeErrorMessage] =
    React.useState<string | undefined>("");

  const [showPasswordMessage, setShowPasswordMessage] =
    React.useState<boolean>(false);

  const passwordMessage =
    "Password must be at least 8 characters long and contain at least one number, one uppercase letter, and one special character";
  const confirmPasswordMessage = "Passwords do not match";

  const validatePassword = (password: string) => {
    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSymbol = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);

    return (
      password.length === 0 ||
      (password.length >= minLength &&
        hasUppercase &&
        hasLowercase &&
        hasNumber &&
        hasSymbol)
    );
  };

  const arePasswordsMatch = (formData: any) =>
    formData.password === formData.confirmPassword;

  const [showConfirmEmailModal, setShowConfirmEmailModal] =
    React.useState<boolean>(false);
  const [showThankYouModal, setShowThankYouModal] =
    React.useState<boolean>(false);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFormData: React.Dispatch<React.SetStateAction<SignUpStudentRequest>>
  ) => {
    setRequestErrorMessage("");
    setConfirmationCodeErrorMessage("");
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const signUp = async (
    e: React.FormEvent<HTMLFormElement>,
    formData: SignUpStudentRequest
  ) => {
    e.preventDefault();
    setRequestErrorMessage("");
    const result = await authService.signupStudent(formData);
    if (result.statusCode! >= 400 && result.statusCode! < 500) {
      setRequestErrorMessage(result.errorMessage);
      return;
    }

    if (result.statusCode! >= 500) {
      setRequestErrorMessage(
        "An error occured during the request. Please, try again later."
      );
      return;
    }

    setShowConfirmEmailModal(true);
    setShowSignUpBlock(false);
  };

  const confirmEmail = async (
    e: React.FormEvent<HTMLFormElement>,
    confirmationCode: string
  ) => {
    e.preventDefault();
    setConfirmationCodeErrorMessage("");
    const result = await authService.confirmEmail(
      confirmationCode,
      formData.email
    );
    if (result.statusCode! >= 400 && result.statusCode! < 500) {
      setConfirmationCodeErrorMessage(result.errorMessage);
      return;
    }

    if (result.statusCode! >= 500) {
      setConfirmationCodeErrorMessage(
        "An error occured during the request. Please, try again later."
      );
      return;
    }

    setShowThankYouModal(true);
    setShowConfirmEmailModal(false);
    setShowSignUpBlock(false);
  };

  const renderSignUpForm = () => {
    return (
      <>
        {/* FORM */}
        <form
          className="grid grid-cols-1 gap-6"
          onSubmit={async (e: React.FormEvent<HTMLFormElement>) =>
            signUp(e, formData)
          }
        >
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              Email address
            </span>
            <Input
              type="email"
              name="email"
              placeholder="example@example.com"
              className="mt-1"
              value={formData.email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e, setFormData)
              }
            />
          </label>
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              First Name
            </span>
            <Input
              type="text"
              name="firstName"
              placeholder="John"
              className="mt-1"
              value={formData.firstName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e, setFormData)
              }
            />
          </label>
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              Last Name
            </span>
            <Input
              type="text"
              name="lastName"
              placeholder="Watson"
              className="mt-1"
              value={formData.lastName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e, setFormData)
              }
            />
          </label>
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              Phone Number
            </span>
            <Input
              type="number"
              name="phoneNumber"
              placeholder="+1 123 456 7890"
              className="mt-1"
              value={formData.phoneNumber}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e, setFormData)
              }
            />
          </label>
          <label className="block">
            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
              Password
            </span>
            <Input
              type="password"
              name="password"
              className="mt-1"
              value={formData.password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleInputChange(e, setFormData);
              }}
            />
          </label>
          {!validatePassword(formData.password) ? (
            <span className="block text-center text-red-500 dark:text-red-500">
              {passwordMessage}
            </span>
          ) : null}
          <label className="block">
            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
              Confirm Password
            </span>
            <Input
              type="password"
              name="confirmPassword"
              className="mt-1"
              value={formData.confirmPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e, setFormData)
              }
            />
          </label>
          {!arePasswordsMatch(formData) ? (
            <span className="block text-center text-red-500 dark:text-red-500">
              {confirmPasswordMessage}
            </span>
          ) : null}
          {requestErrorMessage !== "" ? (
            <span className="block text-center text-red-500 dark:text-red-500">
              {requestErrorMessage}
            </span>
          ) : null}
          <ButtonPrimary
            type="submit"
            disabled={
              !arePasswordsMatch(formData) ||
              !validatePassword(formData.password) ||
              formData.email === "" ||
              formData.firstName === "" ||
              formData.lastName === "" ||
              formData.phoneNumber === "" ||
              formData.password === "" ||
              formData.confirmPassword === ""
            }
          >
            Sign Up
          </ButtonPrimary>
        </form>
        {/* ==== */}
      </>
    );
  };

  const renderConfirmEmailForm = () => {
    return (
      <>
        {/* FORM */}
        <form
          className="grid grid-cols-1 gap-6"
          onSubmit={async (e: React.FormEvent<HTMLFormElement>) =>
            confirmEmail(e, confirmationCode)
          }
        >
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              Confirmation code
            </span>
            <Input
              type="text"
              name="confirmationCode"
              placeholder="000000"
              className="mt-1"
              value={confirmationCode}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setConfirmationCode(e.target.value)
              }
            />
          </label>
          {confirmationCodeErrorMessage !== "" ? (
            <span className="block text-center text-red-500 dark:text-red-500">
              {confirmationCodeErrorMessage}
            </span>
          ) : null}

          <ButtonPrimary type="submit">Confirm</ButtonPrimary>
        </form>
        {/* ==== */}
      </>
    );
  };

  const renderThankYouModal = () => {
    return (
      <>
        <h3 className="my-20 flex text-center items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Thank you for signing up!
        </h3>
        <span className="block text-center text-neutral-700 dark:text-neutral-300">
          Now you can sign in to your account and fill your profile.
        </span>
        <Link
          className="text-primary-500 hover:text-primary-700 dark:hover:text-primary-400"
          to="/login"
        >
          Sign in
        </Link>
      </>
    );
  };

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up || QA Offer</title>
      </Helmet>

      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Sign Up
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-xl bg-white dark:text-neutral-400 dark:bg-neutral-900">
              {formData.role === "student" ? "Student" : "Mentor"}
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>

          {showThankYouModal
            ? renderThankYouModal()
            : showConfirmEmailModal
            ? renderConfirmEmailForm()
            : renderSignUpForm()}

          {showSignUpBlock && (
            <div>
              <span className="block text-center text-neutral-700 dark:text-neutral-300">
                Already have an account? {` `}
                <Link
                  className="text-primary-500 hover:text-primary-700 dark:hover:text-primary-400"
                  to="/login"
                >
                  Sign in
                </Link>
              </span>
              <span className="block text-center text-neutral-700 dark:text-neutral-300">
                Do you want to join as a mentor? {` `}
                <Link
                  className="text-primary-500 hover:text-primary-700 dark:hover:text-primary-400"
                  to="/signup-mentors"
                >
                  Become a mentor
                </Link>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
