import { Tab } from "@headlessui/react";
import Heading2 from "components/Heading/Heading2";
import MentorCard from "components/MentorsComponent/MentorsCardComponent";
import { FC, Fragment, useEffect, useState } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { SectionGridFilterCardProps } from "./MentorsListingPage";
import { mentorsService } from "services/MentorsService";
import accountService from "services/AccountService";
import {
  MentorCardData,
  MentorPreviewProfile,
} from "models/MentorsModels/MentorAccount";

const SectionGridFilteredMentors: FC<SectionGridFilterCardProps> = ({
  className = "",
}) => {
  //let [categories] = useState(["QA", "Development", "Machine Learning"]);
  let [categories] = useState(["QA"]);
  let [mentorsCount, setMentorsCount] = useState(0);

  const [mentors, setMentors] = useState<MentorCardData[]>([]);
  useEffect(() => {
    mentorsService.getMentors<MentorPreviewProfile[]>(0).then((res) => {
      const mentorsCards: MentorCardData[] = (res.data?.data || []).map(
        (mentor) => ({
          id: mentor.id || "",
          name: `${mentor.firstName || ""} ${mentor.lastName || ""}`,
          avatarLink: accountService.getMentorProfilePictureUrl(mentor.id), // Replace with actual avatar link
          href: `/mentors/${mentor.id}`, // Replace with actual href link
          price: mentor.price || 0,
        })
      );
      setMentors(mentorsCards);
      setMentorsCount(mentorsCards.length);
    });
  }, []);

  useEffect(() => {
    setMentorsCount(mentors.length);
  }, []);

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2
        heading="Best Mentors In The Industry"
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            {mentorsCount} {mentorsCount > 1 ? "mentors" : "mentor"}
          </span>
        }
      />
      <div>
        <Tab.Group>
          <Tab.List className="flex space-x-1 overflow-x-auto">
            {categories.map((item) => (
              <Tab key={item} as={Fragment}>
                {({ selected }) => (
                  <button
                    className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                      selected
                        ? "bg-secondary-900 text-secondary-50 "
                        : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                    } `}
                  >
                    {item}
                  </button>
                )}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel className="">
              {/* <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2"> */}
              <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {mentors.map((mentor) => (
                  <MentorCard key={mentor.id} data={mentor} />
                ))}
              </div>
              <div className="flex mt-11 justify-center items-center">
                <ButtonSecondary>Show me more</ButtonSecondary>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default SectionGridFilteredMentors;
