import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React from "react";
import { Link, useParams } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import infoCardsListing from "data/cardInformations";
import {
  ArrowUturnLeftIcon,
  ArrowLeftOnRectangleIcon,
} from "@heroicons/react/24/solid";
import { Button } from "react-bootstrap";

const CardInformation = () => {
  // get id from route params
  const { id } = useParams<{ id: string }>();
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <Button
            className={`ttnc-ButtonSecondary font-medium  bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800`}
            onClick={() => window.history.back()}
          >
            <ArrowUturnLeftIcon className="w-7 h-7 mr-2" />
          </Button>
          <h1 className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl ">
            {infoCardsListing.find((x) => x.id == id)?.header}
          </h1>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
            {infoCardsListing.find((x) => x.id == id)?.intro}
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <p className="pt-5">
          <strong>What's included?</strong>
        </p>
        <ul>
          {infoCardsListing
            .find((x) => x.id == id)
            ?.list.map((x, i) =>
              x[0] == "_" ? (
                <p key={"included__" + i}>{x.replace("__", "")}</p>
              ) : (
                <li key={"included__" + i}>{x}</li>
              )
            )}
        </ul>
        {/* get <p> tag in the outro text and render it */}

        <div
          dangerouslySetInnerHTML={{
            __html: infoCardsListing.find((x) => x.id == id)?.outro ?? "",
          }}
        ></div>
        <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>{infoCardsListing.find((x) => x.id == id)?.header}</title>
      </Helmet>
      {renderHeader()}
      {/* <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src="https://images.unsplash.com/photo-1605487903301-a1dff2e6bbbe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1957&q=80"
      /> */}

      <div className="nc-SingleContent container space-y-10 pb-16">
        {renderContent()}
        {/* {renderTags()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {renderAuthor()}
        {renderCommentForm()}
        {renderCommentLists()} */}
      </div>
    </div>
  );
};

export default CardInformation;
