import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";
import { RequestConfig } from "./RequestConfig";
import { ErrorModel } from "models/ErrorModel";

interface ServiceResult<T> {
  data?: T;
  errorMessage?: string | undefined;
  statusCode?: number;
}

class MentorsService {
  private api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_AUTH_API,
      timeout: 10000,
    });
  }

  public async getMentors<T>(
    count: number
  ): Promise<ServiceResult<AxiosResponse<T>>> {
    try {
      const response = await this.api.get<T>(
        count > 0
          ? "home/mentors-profiles?count=" + count
          : "home/mentors-profiles",
        RequestConfig
      );

      return { data: response, statusCode: response.status };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // Axios error handling
        const axiosError = error as AxiosError<ErrorModel>;
        if (axiosError.response?.data.errors) {
          return {
            errorMessage: axiosError.response?.data.errors[0],
            statusCode: axiosError.response?.status,
          };
        }

        return {
          errorMessage: "Something went wrong",
          statusCode: axiosError.response?.status,
        };
      } else {
        // Other error handling
        const generalError = error as Error;
        console.error("General error:", generalError.message);
        return { errorMessage: generalError.message };
      }
    }
  }

  public async getMentorById<T>(
    id: string
  ): Promise<ServiceResult<AxiosResponse<T>>> {
    try {
      const response = await this.api.get<T>(
        `home/mentor-profile?profileId=${id}`,
        RequestConfig
      );

      return { data: response, statusCode: response.status };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // Axios error handling
        const axiosError = error as AxiosError<ErrorModel>;
        if (axiosError.response?.data.errors) {
          return {
            errorMessage: axiosError.response?.data.errors[0],
            statusCode: axiosError.response?.status,
          };
        }

        return {
          errorMessage: "Something went wrong",
          statusCode: axiosError.response?.status,
        };
      } else {
        // Other error handling
        const generalError = error as Error;
        console.error("General error:", generalError.message);
        return { errorMessage: generalError.message };
      }
    }
  }

  public async getMentorSchedule<T>(
    id: string
  ): Promise<ServiceResult<AxiosResponse<T>>> {
    try {
      const response = await this.api.get<T>(
        `home/mentor-schedule?profileId=${id}`,
        RequestConfig
      );

      return { data: response, statusCode: response.status };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // Axios error handling
        const axiosError = error as AxiosError<ErrorModel>;
        if (axiosError.response?.data.errors) {
          return {
            errorMessage: axiosError.response?.data.errors[0],
            statusCode: axiosError.response?.status,
          };
        }

        return {
          errorMessage: "Something went wrong",
          statusCode: axiosError.response?.status,
        };
      } else {
        // Other error handling
        const generalError = error as Error;
        console.error("General error:", generalError.message);
        return { errorMessage: generalError.message };
      }
    }
  }
}

export const mentorsService = new MentorsService();
