export const RequestConfig = {
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
  timeout: 100000,
};

export const RequestConfigFormData = {
  headers: { "Content-Type": "multipart/form-data" },
  withCredentials: true,
};
