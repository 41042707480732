import { FC, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import authService from "services/AuthService";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";

interface ResetPasswordRequest {
  password: string;
  confirmPassword: string;
  hash: string;
}

const PageResetPassword: FC = () => {
  const queryParams = new URLSearchParams(window.location.search);

  const [successMsg, setSuccessMsg] = useState<string>("");
  const [request, setRequest] = useState<ResetPasswordRequest>({
    password: "",
    confirmPassword: "",
    hash: queryParams.get("hash") || "",
  });

  const passwordMessage =
    "Password must be at least 8 characters long and contain at least one number, one uppercase letter, and one special character";
  const confirmPasswordMessage = "Passwords do not match";

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFormData: React.Dispatch<React.SetStateAction<ResetPasswordRequest>>
  ) => {
    setRequestErrorMessage("");
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [requestErrorMessage, setRequestErrorMessage] = useState<
    string | undefined
  >("");

  const resetPassword = async (
    e: React.FormEvent<HTMLFormElement>,
    request: ResetPasswordRequest
  ) => {
    e.preventDefault();
    const result = await authService.resetPassword(
      request.password,
      request.confirmPassword,
      request.hash
    );
    if (result?.statusCode !== 200 && result?.statusCode! < 500) {
      setRequestErrorMessage(result?.errorMessage);
      return;
    }
    if (result?.statusCode! >= 500) {
      setRequestErrorMessage("Server error. Please try again later.");
      return;
    }
    if (result?.statusCode === 200) {
      setSuccessMsg("Your password updated successfully. Now you can login");
    }
  };

  const validatePassword = (password: string) => {
    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSymbol = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);

    return (
      password.length === 0 ||
      (password.length >= minLength &&
        hasUppercase &&
        hasLowercase &&
        hasNumber &&
        hasSymbol)
    );
  };

  const arePasswordsMatch = (formData: any) =>
    formData.password === formData.confirmPassword;

  return (
    <div className="flex flex-col items-center justify-center min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md mx-auto space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-white">
            Reset password
          </h2>
        </div>
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          <form
            className="grid grid-cols-1 gap-6"
            onSubmit={async (e: React.FormEvent<HTMLFormElement>) =>
              await resetPassword(e, request)
            }
          >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input
                type="password"
                name="password"
                placeholder="Password"
                className="mt-1"
                value={request.password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e, setRequest)
                }
              />
            </label>
            {!validatePassword(request.password) ? (
              <span className="block text-center text-red-500 dark:text-red-500">
                {passwordMessage}
              </span>
            ) : null}

            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Confirm Password
              </span>
              <Input
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                className="mt-1"
                value={request.confirmPassword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e, setRequest)
                }
              />
            </label>
            {!arePasswordsMatch(request) ? (
              <span className="block text-center text-red-500 dark:text-red-500">
                {confirmPasswordMessage}
              </span>
            ) : null}
            {requestErrorMessage !== "" ? (
              <span className="block text-center text-red-500 dark:text-red-500">
                {requestErrorMessage}
              </span>
            ) : null}
            {successMsg !== "" ? (
              <span className="block text-center text-green-500 dark:text-green-500">
                {successMsg}
              </span>
            ) : null}
            <ButtonPrimary
              type="submit"
              disabled={
                !arePasswordsMatch(request) ||
                !validatePassword(request.password) ||
                request.password === "" ||
                request.confirmPassword === ""
              }
              className="btn btn-primary btn-block"
            >
              Reset Password
            </ButtonPrimary>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PageResetPassword;
