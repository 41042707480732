import CommonLayout from "containers/AccountPage/CommonLayout";
import SiteHeader from "containers/SiteHeader";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LogoutPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem("role");
    localStorage.removeItem("name");

    if (localStorage.getItem("role") === null) {
      navigate("/", { replace: true });
    }
  }, []);

  return (
    <div>
      <SiteHeader />
    </div>
  );
};

export default LogoutPage;
