import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";
import { RequestConfig } from "./RequestConfig";
import { CreateMentorsScheduleRequest } from "models/ScheduleModels/CreateMentorsScheduleRequest";
import { CreateMentorAppointmentRequest } from "models/ScheduleModels/CreateMentorAppointment";
import { ErrorModel } from "models/ErrorModel";
import { CancelAppointmentRequest } from "models/ScheduleModels/CancelAppointment";

interface ServiceResult<T> {
  data?: T;
  errorMessage?: string | undefined;
  statusCode?: number;
}

class ScheduleService {
  private api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_AUTH_API,
      timeout: 10000,
    });
  }

  public async createMentorsSchedule(
    data: CreateMentorsScheduleRequest
  ): Promise<ServiceResult<AxiosResponse>> {
    try {
      const response = await this.api.post(
        "profile/schedule",
        data,
        RequestConfig
      );
      return { data: response, statusCode: response.status, errorMessage: "" };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // Axios error handling
        const axiosError = error as AxiosError<ErrorModel>;
        if (axiosError.response?.data.errors) {
          return {
            errorMessage: axiosError.response?.data.errors[0],
            statusCode: axiosError.response?.status,
          };
        }

        return {
          errorMessage: "Something went wrong",
          statusCode: axiosError.response?.status,
        };
      } else {
        // Other error handling
        const generalError = error as Error;
        console.error("General error:", generalError.message);
        return { errorMessage: generalError.message };
      }
    }
  }

  public async getMentorsWeekSchedule<T>(): Promise<
    ServiceResult<AxiosResponse<T>>
  > {
    try {
      const response = await this.api.get(
        "profile/schedule/week",
        RequestConfig
      );

      return { data: response, statusCode: response.status, errorMessage: "" };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // Axios error handling
        const axiosError = error as AxiosError<ErrorModel>;
        if (axiosError.response?.data.errors) {
          return {
            errorMessage: axiosError.response?.data.errors[0],
            statusCode: axiosError.response?.status,
          };
        }

        return {
          errorMessage: "Something went wrong",
          statusCode: axiosError.response?.status,
        };
      } else {
        // Other error handling
        const generalError = error as Error;
        console.error("General error:", generalError.message);
        return { errorMessage: generalError.message };
      }
    }
  }

  public async getMentorsAppointments<T>(
    id: string
  ): Promise<ServiceResult<AxiosResponse<T>>> {
    try {
      const response = await this.api.get(
        `home/mentor-schedule?profileId=${id}`,
        RequestConfig
      );

      return { data: response, statusCode: response.status, errorMessage: "" };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // Axios error handling
        const axiosError = error as AxiosError<ErrorModel>;
        if (axiosError.response?.data.errors) {
          return {
            errorMessage: axiosError.response?.data.errors[0],
            statusCode: axiosError.response?.status,
          };
        }

        return {
          errorMessage: "Something went wrong",
          statusCode: axiosError.response?.status,
        };
      } else {
        // Other error handling
        const generalError = error as Error;
        console.error("General error:", generalError.message);
        return { errorMessage: generalError.message };
      }
    }
  }

  public async createMentorsAppointment(
    request: CreateMentorAppointmentRequest
  ): Promise<ServiceResult<AxiosResponse>> {
    try {
      const response = await this.api.post(
        `home/appointment`,
        request,
        RequestConfig
      );

      return { data: response, statusCode: response.status, errorMessage: "" };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // Axios error handling
        const axiosError = error as AxiosError<ErrorModel>;
        if (axiosError.response?.data.errors) {
          return {
            errorMessage: axiosError.response?.data.errors[0],
            statusCode: axiosError.response?.status,
          };
        }

        return {
          errorMessage: "Something went wrong",
          statusCode: axiosError.response?.status,
        };
      } else {
        // Other error handling
        const generalError = error as Error;
        console.error("General error:", generalError.message);
        return { errorMessage: generalError.message };
      }
    }
  }

  public async getStudentAppointments<T>(
    role: string
  ): Promise<ServiceResult<AxiosResponse<T>>> {
    try {
      const response = await this.api.get(
        `${role}s/profile/appointments`,
        RequestConfig
      );

      return { data: response, statusCode: response.status, errorMessage: "" };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // Axios error handling
        const axiosError = error as AxiosError<ErrorModel>;
        if (axiosError.response?.data.errors) {
          return {
            errorMessage: axiosError.response?.data.errors[0],
            statusCode: axiosError.response?.status,
          };
        }

        return {
          errorMessage: "Something went wrong",
          statusCode: axiosError.response?.status,
        };
      } else {
        // Other error handling
        const generalError = error as Error;
        console.error("General error:", generalError.message);
        return { errorMessage: generalError.message };
      }
    }
  }

  public async cancelAppointment<T>(
    request: CancelAppointmentRequest
  ): Promise<ServiceResult<AxiosResponse<T>>> {
    try {
      const response = await this.api.delete(
        `home/appointment?timezoneId=${request.timezoneId}&date=${request.date}`,
        RequestConfig
      );

      return { data: response, statusCode: response.status, errorMessage: "" };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // Axios error handling
        const axiosError = error as AxiosError<ErrorModel>;
        if (axiosError.response?.data.errors) {
          return {
            errorMessage: axiosError.response?.data.errors[0],
            statusCode: axiosError.response?.status,
          };
        }

        return {
          errorMessage: "Something went wrong",
          statusCode: axiosError.response?.status,
        };
      } else {
        // Other error handling
        const generalError = error as Error;
        console.error("General error:", generalError.message);
        return { errorMessage: generalError.message };
      }
    }
  }
}

const scheduleService = new ScheduleService();
export default scheduleService;
