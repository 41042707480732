import React, { FC } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { LoginRequest } from "models/AuthModels/LoginRequest";
import authService from "services/AuthService";

export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = React.useState<LoginRequest>({
    email: "",
    password: "",
  });
  const [requestErrorMessage, setRequestErrorMessage] = React.useState<
    string | undefined
  >("");

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFormData: React.Dispatch<React.SetStateAction<LoginRequest>>
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const login = async (
    e: React.FormEvent<HTMLFormElement>,
    formData: LoginRequest
  ) => {
    e.preventDefault();
    const result = await authService.login(formData);
    if (result?.statusCode !== 200 && result?.statusCode! < 500) {
      setRequestErrorMessage(result?.errorMessage);
      return;
    }

    if (result?.statusCode! >= 500) {
      setRequestErrorMessage(
        "An error occurred during the request. Please try again later."
      );
      return;
    }

    localStorage.setItem("role", result?.data?.data.role);
    dispatchEvent(new Event("LOGIN_SUCCESS"));
    if (result?.data?.data.role === "student") {
      navigate("/student");
    } else {
      navigate("/mentor");
    }
  };

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || QA Offer</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          <form
            className="grid grid-cols-1 gap-6"
            onSubmit={async (e: React.FormEvent<HTMLFormElement>) =>
              await login(e, formData)
            }
          >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                name="email"
                placeholder="example@example.com"
                className="mt-1"
                value={formData.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e, setFormData)
                }
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
              </span>
              <Input
                type="password"
                name="password"
                className="mt-1"
                value={formData.password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleInputChange(e, setFormData);
                }}
              />
              <span className="forgot-password flex items-right text-neutral-800 dark:text-neutral-200">
                <Link to="/forgot-password" className="">
                  Forgot password?
                </Link>
              </span>
            </label>
            {requestErrorMessage !== "" ? (
              <span className="block text-center text-red-500 dark:text-red-500">
                {requestErrorMessage}
              </span>
            ) : null}
            <ButtonPrimary
              type="submit"
              disabled={formData.email === "" || formData.password === ""}
            >
              Login
            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link
              className="text-primary-500 hover:text-primary-700 dark:hover:text-primary-400"
              to="/signup"
            >
              Create an account
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
